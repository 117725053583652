import { HandlerContext } from '../contexts/ActionContext';
import { LogLevel } from '../contexts/LoggerContext';
import { MageOrder } from './Magento.types';
import { parseMageOrder } from './Magento.utils';
import { MagentoApi } from './MagentoApi';
import { MyParcelExportData } from './MyParcel.types';

export class MagentoOrderApi extends MagentoApi {
	public async getOrder(publicId: string, getShipments = false) {
		const apiUrl = `orders?searchCriteria[filter_groups][0][filters][0][field]=increment_id&searchCriteria[filter_groups][0][filters][0][value]=${publicId}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq`;
		const orderData = await this._call(apiUrl);

		if (orderData && orderData['items'] && orderData['items'].length) {
			return parseMageOrder(
				orderData['items'][0],
				getShipments
					? [
							await this.getShipmentId(
								orderData['items'][0]['entity_id'],
							),
					  ].filter((v) => v)
					: [],
			);
		}

		return null;
	}

	public async createShipment(order: MageOrder): Promise<any> {
		const apiUrl = `order/${order.techId}/ship`;
		const items = order.items.map((item) => {
			return {
				order_item_id: item.techId,
				qty: item.qty.ordered,
			};
		});
		const shipmentId = await this._call(apiUrl, {
			items,
			notify: true,
		});

		return shipmentId;
	}

	public async getShipmentId(techId: number) {
		const apiUrl = `shipments?searchCriteria[filterGroups][0][filters][0][field]=order_id&searchCriteria[filterGroups][0][filters][0][value]=${techId}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq`;
		const shipmentData = await this._call(apiUrl);
		if (
			shipmentData &&
			shipmentData['items'] &&
			shipmentData['items'].length
		) {
			return shipmentData['items'][0].entity_id;
		}

		return null;
	}

	public async getGlsLabel(shipmentId: number, parcelCount = 1) {
		let apiUrl = `glspdf/${shipmentId}`;
		if (parcelCount > 1) {
			apiUrl += `?parcelCount=${parcelCount}`;
		}
		const pdfLabel = await this._call(apiUrl);

		return pdfLabel;
	}

	public async getMyParcelLabel(shipmentId: number, parcelCount = 1) {
		let apiUrl = `myparcelpdf/${shipmentId}`;
		if (parcelCount > 1) {
			apiUrl += `?parcelCount=${parcelCount}`;
		}
		const pdfLabel = await this._call(apiUrl);

		return pdfLabel;
	}

	public async getMyParcelExportLabel(
		shipmentId: number,
		exportData: MyParcelExportData,
		parcelCount = 1,
	) {
		let apiUrl = `exportpdf/${shipmentId}`;
		if (parcelCount > 1) {
			apiUrl += `?parcelCount=${parcelCount}`;
		}
		const pdfLabel = await this._call(apiUrl, exportData);

		return pdfLabel;
	}

	public async getBuzDpdLabel(shipmentId: number, parcelCount = 1) {
		let apiUrl = `buzdpdpdf/${shipmentId}`;
		if (parcelCount > 1) {
			apiUrl += `?parcelCount=${parcelCount}`;
		}
		const pdfLabel = await this._call(apiUrl);

		return pdfLabel;
	}

	public async getBuzLetterboxLabel(shipmentId: number, parcelCount = 1) {
		let apiUrl = `buzletterpdf/${shipmentId}`;
		if (parcelCount > 1) {
			apiUrl += `?parcelCount=${parcelCount}`;
		}
		const pdfLabel = await this._call(apiUrl);

		return pdfLabel;
	}

	public async resendWarehouseMail(techId: number, context: HandlerContext) {
		const { addLog } = context;

		const apiUrl = `warehouse/${techId}`;
		await this._call(apiUrl);

		addLog('app', `Warehouse Mail resend for ${techId}`, LogLevel.success);

		return true;
	}

	public async updateStatus(
		techId: number,
		newStatus: string,
		publicUpdate: 0 | 1 = 1,
	) {
		const apiUrl = `orders/${techId}/comments`;

		return await this._call(apiUrl, {
			statusHistory: {
				comment: '',
				is_customer_notified: publicUpdate,
				is_visible_on_front: 1,
				status: newStatus,
			},
		});
	}
}
