import { HandlerContext } from '../contexts/ActionContext';
import { LogLevel } from '../contexts/LoggerContext';

export const getBuzDpdLabel = async (
	shipmentId: number,
	orderId: string,
	context: HandlerContext,
) => {
	const { addLog, mageOrderApi, parcelCount } = context;
	addLog(
		'app',
		`Fetching BUZ-DPD label for [${orderId}|${shipmentId}]`,
		LogLevel.bright,
	);

	const buzDpdLabel = await mageOrderApi.getBuzDpdLabel(
		shipmentId,
		parcelCount,
	);

	if (!buzDpdLabel) {
		addLog('app', `No label received!`, LogLevel.error);

		return false;
	}

	const errorMessage = buzDpdLabel?.message || buzDpdLabel[0]?.message;
	if (errorMessage) {
		addLog('mage', errorMessage, LogLevel.error);

		return false;
	}

	return buzDpdLabel;
};

export const getBuzLetterboxLabel = async (
	shipmentId: number,
	orderId: string,
	context: HandlerContext,
) => {
	const { addLog, mageOrderApi, parcelCount } = context;
	addLog(
		'app',
		`Fetching BUZ Brievenbus label for [${orderId}|${shipmentId}]`,
		LogLevel.bright,
	);

	const buzDpdLabel = await mageOrderApi.getBuzLetterboxLabel(
		shipmentId,
		parcelCount,
	);

	if (!buzDpdLabel) {
		addLog('app', `No label received!`, LogLevel.error);

		return false;
	}

	const errorMessage = buzDpdLabel?.message || buzDpdLabel[0]?.message;
	if (errorMessage) {
		addLog('mage', errorMessage, LogLevel.error);

		return false;
	}

	return buzDpdLabel;
};
